import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '../../../../components/LocaleNumber';
import NumberInput from '../../../../components/NumberInput';
import RefuseSampling from '../../../../components/RefuseSampling';
import ScaledInput from '../../../../components/ScaledInput';
import { stateContext } from '../../reducer';
import Time from './containers/components/Time';
import {
  PrimarySamplesValueGroup,
  ValueGroup,
} from './containers/components/ValueGroups';
import { calculatePrimarySamples } from './containers/ContainersGT100kg';
import styles from './Treated.module.scss';

const Values = ({
  minPrimarySamples,
  minPrimarySamplesWithSafety,
  samplingInterval,
  packingTimeSeconds,
}) => {
  const { t } = useTranslation();
  return (
    <div className="ValueGroups">
      <PrimarySamplesValueGroup value={minPrimarySamples} />
      <ValueGroup
        big
        bold
        label={t('autoSamplerLabelPrimarySamples')}
        value={minPrimarySamplesWithSafety}
      />

      <span className="Label Bold Big">{t('autoSamplerLabelInterval')}</span>
      <Time className="Content Bold Big" seconds={samplingInterval} />

      <span className="Label">{t('autoSamplerLabelPackingTime')}</span>
      <Time className="Content" seconds={packingTimeSeconds} />

      <ValueGroup
        label={t('autoSamplerLabelInterval')}
        value={samplingInterval}
        unit={t('unitSeconds')}
      />
    </div>
  );
};

const AutoSampler = () => {
  const [lotWeight, setLotWeight] = useState(null);
  const [seedsPerHour, setSeedsPerHour] = useState(null);
  const { species } = useContext(stateContext);
  const { t } = useTranslation();

  const maxLotWeight = species.max_lot_weight.value * 1.05;
  const approvedMaxLotWeight = species.approved_max_lot_weight
    ? species.approved_max_lot_weight.value * 1.05
    : null;
  const isPoaceae = approvedMaxLotWeight !== null;

  const minPrimarySamples = lotWeight
    ? calculatePrimarySamples(lotWeight)
    : null;
  const minPrimarySamplesWithSafety = lotWeight
    ? Math.ceil(minPrimarySamples * 1.1)
    : null;
  const packingTimeSeconds = lotWeight
    ? lotWeight / ((seedsPerHour * 1000) / 3600)
    : null;

  const samplingInterval = packingTimeSeconds
    ? packingTimeSeconds / minPrimarySamples
    : null;

  const exceedsMax = lotWeight && lotWeight > maxLotWeight;
  const exceedsApprovedMax =
    isPoaceae && lotWeight && lotWeight > approvedMaxLotWeight;

  const showExceedsMaxWarning = exceedsMax;
  const showContainerValues =
    (isPoaceae && !exceedsApprovedMax) || (!isPoaceae && !exceedsMax);
  const showPoaceaeExceedsMaxWarning =
    isPoaceae && exceedsMax && !exceedsApprovedMax;

  const refuseSamplingStyle = exceedsApprovedMax
    ? 'Error'
    : isPoaceae && exceedsMax
    ? 'Warning'
    : 'Error';

  return (
    <div>
      <div className="InputGroup">
        <span>{t('autoSamplerTotalLotWeight')}</span>
        <ScaledInput value={lotWeight} onChange={setLotWeight} />
      </div>
      <div className="InputGroup">
        <span>{t('autoSamplerSeedFlow')}</span>
        <NumberInput value={seedsPerHour} onChange={setSeedsPerHour} />
      </div>

      <hr />

      {showExceedsMaxWarning && (
        <RefuseSampling style={refuseSamplingStyle}>
          <span>
            {t(
              exceedsApprovedMax
                ? 'treatedExceedsMaxApproved'
                : 'treatedExceedsMaxNotApproved',
              {
                maxLotWeight: formatNumber(maxLotWeight / 1000),
                approvedMaxLotWeight: formatNumber(approvedMaxLotWeight / 1000),
              }
            )}
          </span>
        </RefuseSampling>
      )}

      {showPoaceaeExceedsMaxWarning && (
        <div className={styles.PoaceaeExceedsMaxHeading}>
          <span>{t('poaceaeExceedsMaxHeading')}</span>
        </div>
      )}

      {showContainerValues && (
        <Values
          {...{
            minPrimarySamples,
            minPrimarySamplesWithSafety,
            samplingInterval,
            packingTimeSeconds,
          }}
        />
      )}
    </div>
  );
};

export default AutoSampler;
