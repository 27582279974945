import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeClass } from '../utils';

const CloseButton = ({ onClose, classNames = [] }) => {
  const { t } = useTranslation();

  return (
    <button className={makeClass(classNames)} onClick={onClose}>
      {t('btnClose')}
    </button>
  );
};

export default CloseButton;
