import { createContext } from 'react';

export const SAMPLED_FROM = Object.freeze({
  UNSELECTED: 'unselected',
  CONTAINER: 'container',
  STREAM: 'stream',
});

export const SEED_TYPE = Object.freeze({
  UNSELECTED: 'unselected',
  TREATED: 'treated',
  COATED: 'coated',
  TAPES: 'tapes',
});

export const ACTIONS = Object.freeze({
  CONTAINER_WEIGHT: 'containerWeight',
  DEFAULT_INPUTS: 'defaultInputs',
  HELP_TYPE: 'help_type',
  HETEROGENEITY_MODE_DISABLE: 'heterogeneity_mode_disable',
  HETEROGENEITY_MODE_ENABLE: 'heterogeneity_mode_enable',
  LOCKED_CONTAINER_WEIGHT: 'lockedContainerWeight',
  LOCKED_LOT_WEIGHT: 'lockedLotWeight',
  LOCKED_N_CONTAINERS: 'lockedNContainers',
  LOT_WEIGHT: 'lotWeight',
  N_CONTAINERS: 'nContainers',
  SAMPLED_FROM: 'sampledFrom',
  SEED_TYPE: 'seedType',
  SEED_UNITS_PER_WEIGHT: 'seedUnitsPerWeight',
  TOGGLE_FAVOURITE: 'toggleFavourite',
  UNIT_WEIGHT: 'unitWeight',
  WIZARD_FALSE_HETEROGENEITY_TRUE: 'wizard_false_heterogeneity_true',
  WIZARD_SHOWING: 'wizardShowing',
});

const loadFavouriteList = () =>
  JSON.parse(localStorage.getItem('favourites')) || [];

const saveFavouriteList = favouriteList =>
  localStorage.setItem('favourites', JSON.stringify(favouriteList));

const defaultInputsState = {
  nContainers: null,
  containerWeight: null,
  lotWeight: null,
  seedUnitsPerWeight: null,
  unitWeight: null,
  seedFlow: null,
  heterogeneityMode: false,
  locked: {
    nContainers: false,
    containerWeight: false,
    lotWeight: true,
  },
};

export const initState = species => {
  const speciesKey = species.key;
  const favouriteList = loadFavouriteList();
  const isFavourite = favouriteList.includes(speciesKey);

  return {
    favouriteList,
    helpType: null,
    inputs: { ...defaultInputsState },
    isFavourite,
    sampledFrom: SAMPLED_FROM.UNSELECTED,
    seedType: SEED_TYPE.UNSELECTED,
    species,
    wizardShowing: false,
  };
};

export const stateContext = createContext();

const reducer = (state, { action, payload }) => {
  switch (action) {
    case ACTIONS.CONTAINER_WEIGHT:
      return containerWeightChanged(state, payload);
    case ACTIONS.DEFAULT_INPUTS:
      return { ...state, inputs: { ...defaultInputsState } };
    case ACTIONS.HELP_TYPE:
      return { ...state, helpType: payload };
    case ACTIONS.HETEROGENEITY_MODE_DISABLE:
      return { ...state, heterogeneityMode: false };
    case ACTIONS.HETEROGENEITY_MODE_ENABLE:
      return { ...state, heterogeneityMode: true };
    case ACTIONS.LOCKED_CONTAINER_WEIGHT:
    case ACTIONS.LOCKED_LOT_WEIGHT:
    case ACTIONS.LOCKED_N_CONTAINERS:
      return lockContainerInput(state, action);
    case ACTIONS.LOT_WEIGHT:
      return lotWeightChanged(state, payload);
    case ACTIONS.N_CONTAINERS:
      return nContainersChanged(state, payload);
    case ACTIONS.SAMPLED_FROM:
      return { ...state, sampledFrom: payload };
    case ACTIONS.SEED_TYPE:
      return { ...state, seedType: payload };
    case ACTIONS.SEED_UNITS_PER_WEIGHT:
      return seedUnitsPerWeightChanged(state, payload);
    case ACTIONS.UNIT_WEIGHT:
      return unitWeightChanged(state, payload);
    case ACTIONS.TOGGLE_FAVOURITE:
      return toggleSaveAsFavourite(state);
    case ACTIONS.WIZARD_FALSE_HETEROGENEITY_TRUE:
      return { ...state, wizardShowing: false, heterogeneityMode: true };
    case ACTIONS.WIZARD_SHOWING:
      return { ...state, wizardShowing: payload };
    default:
      throw new Error(`Action not defined ${action} ${payload}`);
  }
};

const lockContainerInput = (state, action) => ({
  ...state,
  inputs: {
    ...state.inputs,
    locked: {
      ...state.inputs.locked,
      containerWeight: action === ACTIONS.LOCKED_CONTAINER_WEIGHT,
      lotWeight: action === ACTIONS.LOCKED_LOT_WEIGHT,
      nContainers: action === ACTIONS.LOCKED_N_CONTAINERS,
    },
  },
});

const seedUnitsPerWeightChanged = (state, seedUnitsPerWeight) => {
  return {
    ...state,
    inputs: { ...state.inputs, seedUnitsPerWeight },
  };
};

const unitWeightChanged = (state, unitWeight) => {
  return {
    ...state,
    inputs: { ...state.inputs, unitWeight },
  };
};

const nContainersChanged = (state, nContainers) => {
  const inputs = state.inputs;
  if (inputs.containerWeight && inputs.locked.lotWeight) {
    const lotWeight = nContainers * inputs.containerWeight;
    return { ...state, inputs: { ...inputs, nContainers, lotWeight } };
  }
  if (inputs.lotWeight && inputs.locked.containerWeight) {
    const containerWeight = inputs.lotWeight / nContainers;
    return { ...state, inputs: { ...inputs, nContainers, containerWeight } };
  }
  return { ...state, inputs: { ...inputs, nContainers } };
};

const containerWeightChanged = (state, containerWeight) => {
  const inputs = state.inputs;
  if (inputs.nContainers && inputs.locked.lotWeight) {
    const lotWeight = inputs.nContainers * containerWeight;
    return { ...state, inputs: { ...inputs, containerWeight, lotWeight } };
  }
  if (inputs.lotWeight && inputs.locked.nContainers) {
    const nContainers = inputs.lotWeight / containerWeight;
    return { ...state, inputs: { ...inputs, nContainers, containerWeight } };
  }
  return { ...state, inputs: { ...inputs, containerWeight } };
};

const lotWeightChanged = (state, lotWeight) => {
  const inputs = state.inputs;
  if (inputs.nContainers && inputs.locked.containerWeight) {
    const containerWeight = lotWeight / inputs.nContainers;
    return { ...state, inputs: { ...inputs, containerWeight, lotWeight } };
  }
  if (inputs.containerWeight && inputs.locked.nContainers) {
    const nContainers = lotWeight / inputs.containerWeight;
    return { ...state, inputs: { ...inputs, nContainers, lotWeight } };
  }
  return { ...state, inputs: { ...inputs, lotWeight } };
};

const toggleSaveAsFavourite = state => {
  const speciesKey = state.species.key;
  const newList = state.isFavourite
    ? state.favouriteList.filter(item => item !== speciesKey)
    : [...state.favouriteList, speciesKey];

  saveFavouriteList(newList);
  return {
    ...state,
    favouriteList: newList,
    isFavourite: !state.isFavourite,
  };
};

export default reducer;
