import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage/HomePage';
import SpeciesPage from './pages/SpeciesPage/SpeciesPage';

const App = () => {
  const { i18n } = useTranslation();
  document.documentElement.dir = i18n.dir();
  document.documentElement.lang = i18n.language;

  return (
    <Suspense fallback="Loading...">
      <Router>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/:species_slug" element={<SpeciesPage />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
