import React from 'react';

import { makeClass } from '../utils';
import styles from './Modal.module.scss';

const Modal = ({ children, onClose }) => {
  return (
    <div className={styles.Modal} onClick={onClose}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={makeClass([styles.Container, 'Box'])}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
