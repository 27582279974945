import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FlexSpacer from '../../../../components/FlexSpacer';
import { HELP_TYPE } from '../../../../components/help/Help';
import NumberInput from '../../../../components/NumberInput';
import RefuseSampling from '../../../../components/RefuseSampling';
import { ACTIONS, stateContext } from '../../reducer';
import styles from './Coated.module.scss';
import ContainerValueInputs from './containers/components/ContainerValueInputs';
import OpenWizard from './containers/components/OpenWizard';
import { ValueGroup } from './containers/components/ValueGroups';
import { calculatePrimarySamples } from './containers/ContainersLTE100kg';

const DefaultValues = ({
  primarySamples,
  seedUnitWeight,
  seedUnitsPerGram,
  seedsInLot,
  seedsPerContainer,
  seeds1e9Weight,
  dispatch,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={styles.DefaultValues}
        onClick={() =>
          dispatch({
            action: ACTIONS.HELP_TYPE,
            payload: HELP_TYPE.MIN_SUBMITTED,
          })
        }
      >
        <span>{t('coatedMinSubmitted')}</span>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{ fontSize: '1.25em' }}
          title={t('helpIconTitle')}
        />
      </div>
      <div className="ValueGroups">
        <ValueGroup
          bold
          big
          label={t('coatedPrimarySamples')}
          value={primarySamples}
        />
        <ValueGroup
          bold
          big
          label={t('coatedPurityTSWGermination')}
          value={seedUnitWeight * 2500 * 1.05}
          unit={t('unitG')}
        />
        <ValueGroup
          bold
          big
          label={t('speciesInfoOtherSeeds')}
          value={seedUnitWeight * 10000 * 1.05}
          unit={t('unitG')}
        />
        <ValueGroup
          bold
          big
          label={t('speciesInfoOSD')}
          value={seedUnitWeight * 25000 * 1.05}
          unit={t('unitG')}
        />
        <ValueGroup
          bold
          big
          label={t('speciesInfoSizeGrading')}
          value={seedUnitWeight * 5000 * 1.05}
          unit={t('unitG')}
        />

        <ValueGroup
          label={t('coatedSeedUnitsPerKG')}
          value={Math.round(seedUnitsPerGram * 1000)}
          unit={t('unitSeeds')}
        />
        <ValueGroup
          label={t('coatedSeedsPerContainer')}
          value={Math.round(seedsPerContainer)}
          unit={t('unitSeeds')}
        />
        <ValueGroup
          label={t('coatedSeedsInLot')}
          value={Math.round(seedsInLot)}
          unit={t('unitSeeds')}
        />
        <ValueGroup
          label={t('coatedWeight1Billion')}
          value={Math.round(seeds1e9Weight / 1000)}
          unit={t('unitKG')}
        />
      </div>
    </>
  );
};

const Coated = () => {
  const {
    inputs: {
      nContainers,
      containerWeight,
      lotWeight,
      seedUnitsPerWeight,
      unitWeight,
    },
    dispatch,
  } = useContext(stateContext);
  const { t } = useTranslation();

  const seedUnitWeight =
    unitWeight && seedUnitsPerWeight ? unitWeight / seedUnitsPerWeight : null;

  const seedUnitsPerGram = seedUnitWeight ? 1 / seedUnitWeight : null;
  const seedsInLot = seedUnitWeight
    ? seedUnitsPerGram * nContainers * containerWeight
    : null;
  const seedsPerContainer = seedUnitWeight
    ? containerWeight / seedUnitWeight
    : null;
  const seeds1e9Weight = seedUnitWeight ? seedUnitWeight * 1e9 : null;

  const exceedsMaxSeedCount = seedsInLot ? seedsInLot > 1e9 : null;
  const exceedsMaxWeight = lotWeight ? lotWeight > 42000000 : null;
  const exceedsMax = exceedsMaxSeedCount || exceedsMaxWeight;

  const primarySamples =
    seedsPerContainer < 300_000
      ? calculatePrimarySamples(Math.ceil(seedsInLot / 2_000_000))
      : calculatePrimarySamples(nContainers);

  return (
    <>
      <ContainerValueInputs />
      <div className="InputGroup">
        <div
          onClick={() =>
            dispatch({ action: ACTIONS.HELP_TYPE, payload: HELP_TYPE.COATED })
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <span className="Label">{t('coatedSeedUnitsPerWeightUnit')}</span>
          <FontAwesomeIcon
            icon={faInfoCircle}
            title={t('helpIconTitle')}
            style={{ fontSize: '1.25em' }}
          />
        </div>
        <NumberInput
          value={seedUnitsPerWeight}
          onChange={v =>
            dispatch({ action: ACTIONS.SEED_UNITS_PER_WEIGHT, payload: v })
          }
        />
      </div>
      <div className="InputGroup">
        <div>
          <span className="Label">{t('coatedWeightUnitForSeedUnits')}</span>
        </div>
        <NumberInput
          value={unitWeight}
          onChange={v => dispatch({ action: ACTIONS.UNIT_WEIGHT, payload: v })}
        />
      </div>

      <hr />
      {exceedsMax ? (
        <RefuseSampling>
          {exceedsMaxSeedCount && <span>{t('coatedExceedsMaxSeedCount')}</span>}
          {exceedsMaxWeight && <span>{t('coatedExceedsMaxWeight')}</span>}
        </RefuseSampling>
      ) : (
        <DefaultValues
          {...{
            dispatch,
            primarySamples,
            seeds1e9Weight,
            seedsInLot,
            seedsPerContainer,
            seedUnitsPerGram,
            seedUnitWeight,
          }}
        />
      )}

      <FlexSpacer />
      <OpenWizard />
    </>
  );
};

export default Coated;
