import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LockButton from './LockButton';
import NumberInput from './NumberInput';
import styles from './ScaledInput.module.scss';
import { makeClass } from './utils';

const ScaledInput = ({
  value,
  onChange,
  locked,
  onLocked,
  disabled,
  defaultScale = 1000,
}) => {
  const [scale, setScaleState] = useState(parseInt(defaultScale));
  const { t } = useTranslation();

  const scaleKg = scale === 1000;
  const kgButtonClasses = makeClass([styles.Middle], scaleKg);
  const gButtonClasses = makeClass(
    [locked === undefined ? styles.End : styles.Middle],
    !scaleKg
  );

  const setScale = newScale => {
    onChange((value / scale) * newScale);
    setScaleState(newScale);
  };

  const setOnChange = value => onChange(value * scale);

  return (
    <div className={styles.ScaledInput}>
      <NumberInput
        className={makeClass(['Input', styles.Start])}
        min="0"
        value={isNaN(value) || value === null ? null : value / scale}
        onChange={setOnChange}
        disabled={disabled || locked}
      />
      <button className={kgButtonClasses} onClick={() => setScale(1000)}>
        {t('unitKG')}
      </button>
      <button className={gButtonClasses} onClick={() => setScale(1)}>
        {t('unitG')}
      </button>
      {locked !== undefined ? (
        <LockButton locked={locked} onClick={onLocked} />
      ) : null}
    </div>
  );
};

export default ScaledInput;
