import React from 'react';

import { SAMPLED_FROM, SEED_TYPE } from '../reducer';
import AutoSampler from './forms/AutoSampler';
import Coated from './forms/Coated';
import Heterogeneity from './forms/Heterogeneity';
import TapesAndMats from './forms/TapesAndMats';
import Treated from './forms/Treated';

const FormSwitch = ({ heterogeneityMode, sampledFrom, seedType }) => {
  if (heterogeneityMode) {
    return <Heterogeneity />;
  } else {
    switch (sampledFrom) {
      case SAMPLED_FROM.CONTAINER:
        switch (seedType) {
          case SEED_TYPE.TREATED:
            return <Treated />;
          case SEED_TYPE.COATED:
            return <Coated />;
          case SEED_TYPE.TAPES:
            return <TapesAndMats />;
          default:
            return null;
        }
      case SAMPLED_FROM.STREAM:
        return <AutoSampler />;
      default:
        return null;
    }
  }
};

export default FormSwitch;
