import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { makeClass } from '../../../components/utils';
import { ACTIONS, SEED_TYPE } from '../reducer';
import styles from '../SampledFromButtons/SampledFromButtons.module.scss';

const ContainerTypeButtons = memo(
  ({ seedType, heterogeneityMode, dispatch }) => {
    const { t } = useTranslation();
    const setSeedType = payload =>
      dispatch({ action: ACTIONS.SEED_TYPE, payload });

    return (
      <div className="InputGroup">
        <div className={styles.SampledFromButtons}>
          <button
            className={makeClass(['start'], seedType === SEED_TYPE.TREATED)}
            onClick={() => setSeedType(SEED_TYPE.TREATED)}
          >
            {t('speciesContainerBtnTreated')}
          </button>
          <button
            className={makeClass(['middle'], seedType === SEED_TYPE.COATED)}
            onClick={() => setSeedType(SEED_TYPE.COATED)}
          >
            {t('speciesContainerBtnCoated')}
          </button>
          <button
            className={makeClass(['end'], seedType === SEED_TYPE.TAPES)}
            disabled={heterogeneityMode}
            onClick={() => setSeedType(SEED_TYPE.TAPES)}
          >
            {t('speciesContainerBtnTapesMats')}
          </button>
        </div>
      </div>
    );
  }
);

export default ContainerTypeButtons;
