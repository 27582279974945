import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FlexSpacer from '../../../../components/FlexSpacer';
import { ACTIONS, SEED_TYPE, stateContext } from '../../reducer';
import {
  NContainersInput,
  TotalLotWeightInput,
} from './containers/components/ContainerValueInputs';
import { ValueGroup } from './containers/components/ValueGroups';
import { calculatePrimarySamples } from './containers/ContainersLTE100kg';
import styles from './Heterogeneity.module.scss';

const calculateMinSampledContainers = (nContainers) => {
  if (nContainers < 5) return null;
  if (nContainers < 11) return nContainers;
  if (nContainers < 16) return 11;
  if (nContainers < 26) return 15;
  if (nContainers < 36) return 17;
  if (nContainers < 50) return 18;
  return 20;
};

const calculateMinSampledTapes = (lotWeight) => {
  return calculatePrimarySamples(Math.floor(lotWeight / 300000));
};

const Heterogeneity = () => {
  const {
    inputs: { nContainers, lotWeight },
    seedType,
    dispatch,
  } = useContext(stateContext);
  const { t } = useTranslation();

  const nContainersToBeSampled =
    seedType === SEED_TYPE.TAPES
      ? calculateMinSampledTapes(lotWeight)
      : calculateMinSampledContainers(nContainers);

  const inputs =
    seedType === SEED_TYPE.TAPES ? (
      <TotalLotWeightInput {...{ lotWeight, dispatch }} />
    ) : (
      <NContainersInput {...{ nContainers, dispatch }} />
    );

  return (
    <>
      <div className={styles.Header}>
        <h2>{t('heterogeneityTestingModeTitle')}</h2>
      </div>
      {inputs}

      <hr />

      <div className={styles.Heterogeneity}>
        <div className="ValueGroups">
          <ValueGroup
            bold
            big
            label={t('heterogeneityTestingIndependantContainerSamples')}
            value={nContainersToBeSampled}
          />
        </div>

        <div className={styles.Info}>
          <span>{t('heterogeneityTestingNote')}</span>
        </div>

        <FlexSpacer />

        <div className={styles.DisableButton}>
          <button
            onClick={() =>
              dispatch({ action: ACTIONS.HETEROGENEITY_MODE_DISABLE })
            }
          >
            {t('heterogeneityTestingBtnExit')}
          </button>
        </div>
      </div>
    </>
  );
};

export default Heterogeneity;
