import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './RefuseSampling.module.scss';
import { makeClass } from './utils';

const RefuseSampling = ({ children, style }) => {
  const { t } = useTranslation();

  return (
    <div
      className={makeClass([
        styles.RefuseSampling,
        styles[style] || styles.Error,
      ])}
    >
      <span className={styles.Heading}>{t('refuseSampling')}</span>
      {children}
    </div>
  );
};

export default RefuseSampling;
