import React from 'react';

import LockButton from './LockButton';
import NumberInput from './NumberInput';
import styles from './ScaledInput.module.scss';
import { makeClass } from './utils';

const LockedNumberInput = ({
  value,
  onChange,
  min,
  locked,
  onLocked,
  disabled,
}) => (
  <div className={styles.ScaledInput}>
    <NumberInput
      className={makeClass([
        'Input',
        locked === undefined ? null : styles.Start,
      ])}
      min={min}
      value={value}
      onChange={onChange}
      disabled={disabled || locked}
    />
    {locked !== undefined ? (
      <LockButton locked={locked} onClick={onLocked} />
    ) : null}
  </div>
);

export default LockedNumberInput;
