import React from 'react';

import CloseButton from '../CloseButton';
import styles from './Help.module.scss';
import HelpCoated from './HelpCoated';
import HelpMinSubmitted from './HelpMinSubmitted';

export const HELP_TYPE = {
  COATED: 'coated',
  MIN_SUBMITTED: 'min_submitted',
};

const helpText = (helpType) => {
  switch (helpType) {
    case null:
      return null;
    case HELP_TYPE.COATED:
      return HelpCoated;
    case HELP_TYPE.MIN_SUBMITTED:
      return HelpMinSubmitted;
    default:
      return null;
  }
};

const Help = ({ helpType, onClose }) => {
  const HelpText = helpText(helpType);

  if (HelpText === null) {
    return null;
  }

  return (
    <div className={styles.HelpModalBackground}>
      <div className={styles.HelpModal}>
        <div className={styles.ModalContainer}>
          <div className={styles.Content}>
            <HelpText />
            <CloseButton onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
