import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrimarySamplesValueGroup, ValueGroup } from './components/ValueGroups';
import SamplingIntensity from './SamplingIntensity';

const ceilMax = (value, min) => Math.max(Math.ceil(value), min);

export const calculatePrimarySamples = lotWeight => {
  if (lotWeight === null) return null;
  if (lotWeight < 500000) return 5;
  if (lotWeight < 3000000) return ceilMax(lotWeight / 300000, 5);
  if (lotWeight < 20000000) return ceilMax(lotWeight / 500000, 10);
  return ceilMax(lotWeight / 700000, 40);
};

const LTE15Containers = ({ value: { nContainers, minSamples } }) => {
  const { t } = useTranslation();

  const samplesPerContainer = Math.ceil(minSamples / nContainers);
  const primarySamples = nContainers * samplesPerContainer;

  return (
    <>
      <PrimarySamplesValueGroup value={primarySamples} />
      <ValueGroup
        label={t('minSamplesFromEachContainer')}
        value={samplesPerContainer}
      />
    </>
  );
};

const GT15Containers = ({ value: { nContainers, minSamples } }) => {
  return (
    <>
      <PrimarySamplesValueGroup value={minSamples} />
      <SamplingIntensity value={{ nContainers, minSamples }} />
    </>
  );
};

export const ContainersGT100kg = ({ value: { lotWeight, nContainers } }) => {
  const minSamples = calculatePrimarySamples(lotWeight);

  const container =
    nContainers > 15 ? (
      <GT15Containers value={{ nContainers, minSamples }} />
    ) : (
      <LTE15Containers value={{ nContainers, minSamples }} />
    );

  return <div className="ValueGroups">{container}</div>;
};
