import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ACTIONS, stateContext } from '../pages/SpeciesPage/reducer';
import styles from './ExceedsMaxWizard.module.scss';
import Modal from './Modal';
import { makeClass } from './utils';

const CloseButton = ({ text, openHeterogeneity }) => {
  const { dispatch } = useContext(stateContext);

  const close = () => {
    if (openHeterogeneity) {
      dispatch({ action: ACTIONS.WIZARD_FALSE_HETEROGENEITY_TRUE });
    } else {
      dispatch({ action: ACTIONS.WIZARD_SHOWING, payload: false });
    }
  };

  return (
    <button className={styles.CloseButton} onClick={close}>
      {text}
    </button>
  );
};

const Question = ({ label, value, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.Question}>
      <span className={styles.Heading}>{label}</span>
      <div className={styles.Buttons}>
        <button
          className={makeClass(['start'], value === false)}
          onClick={() => onChange(false)}
        >
          {t('btnQuestionNo')}
        </button>
        <button
          className={makeClass(['end'], value === true)}
          onClick={() => onChange(true)}
        >
          {t('btnQuestionYes')}
        </button>
      </div>
    </div>
  );
};

const IsSeedLotHomogenous = ({ value: [isHomogeneous, setIsHomogenous] }) => {
  const { t } = useTranslation();
  return (
    <Question
      label={t('qSeedLotHomogenous')}
      value={isHomogeneous}
      onChange={setIsHomogenous}
    />
  );
};

const ObtainSample = () => {
  const { t } = useTranslation();
  return (
    <>
      <span className={styles.Heading}>{t('qObtainSample')}</span>
      <CloseButton text={t('btnOkay')} />
    </>
  );
};

const RefuseSamplingText = () => {
  const { t } = useTranslation();
  return (
    <>
      <span className={styles.Refuse}>{t('qRefuseSampling')}</span>
      <CloseButton text={t('btnOkay')} />
    </>
  );
};

const ShouldSampleForHeterogeneity = ({
  value: [shouldSample, setShouldSample],
}) => {
  const { t } = useTranslation();
  return (
    <Question
      label={t('qSampleForHeterogeneity')}
      value={shouldSample}
      onChange={setShouldSample}
    />
  );
};

const ExceedsMaxWizard = () => {
  const [isHomogeneous, setIsHomogenous] = useState(null);
  const [shouldSample, setShouldSample] = useState(null);
  const { t } = useTranslation();

  return (
    <Modal>
      <div className={styles.ExceedsMaxWizard}>
        <IsSeedLotHomogenous value={[isHomogeneous, setIsHomogenous]} />
        {isHomogeneous === true ? <ObtainSample /> : null}
        {isHomogeneous === false ? (
          <>
            <ShouldSampleForHeterogeneity
              value={[shouldSample, setShouldSample]}
            />
            {shouldSample === false ? <RefuseSamplingText /> : null}
            {shouldSample === true ? (
              <CloseButton
                text={t('qBtnSampleHeterogeneity')}
                openHeterogeneity
              />
            ) : null}
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default ExceedsMaxWizard;
