import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Remark } from 'react-remark';
import { useNavigate } from 'react-router-dom';

import FlexSpacer from '../../components/FlexSpacer';
import LanguageSelect from '../../components/LanguageSelect';
import { makeClass } from '../../components/utils';
import SPECIES from '../../data/species.json';
import styles from './HomePage.module.scss';

const itemClass = styles.SpeciesItem;
const itemClassFavourite = makeClass([styles.SpeciesItem, styles.Favourite]);

const matchesFilter = (species, filterRe) =>
  species.name.match(filterRe) ||
  (species.synonym && species.synonym.match(filterRe)) ||
  (species.previously && species.previously.match(filterRe)) ||
  (species.includes && species.includes.match(filterRe));

const SpeciesInfo = ({ name, info }) => {
  if (info === undefined) {
    return null;
  }

  return (
    <div className={styles.Info}>
      <span>{name}</span>
      <div dir="ltr" className={styles.Data}>
        <Remark>{info}</Remark>
      </div>
    </div>
  );
};

const filterSpecies = (filterRe, favouriteList) =>
  Object.entries(SPECIES)
    .filter(([key, species]) =>
      filterRe ? matchesFilter(species, filterRe) : favouriteList.includes(key)
    )
    .sort(([key1], [key2]) =>
      favouriteList.includes(key1) ? (favouriteList.includes(key2) ? 0 : -1) : 1
    );

const SpeciesTableToType = memo(({ className, table }) => {
  const { t } = useTranslation();
  const type = t(
    ['tableAgriculture', 'tableTreeShrub', 'tableFlowerSpiceHerb'][table - 1]
  );

  return <span className={className}>{type}</span>;
});

const SpeciesListItem = ({ species, isFavourite, onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      className={isFavourite ? itemClassFavourite : itemClass}
      onClick={onClick}
    >
      <div className={styles.Name}>
        <div dir="ltr">
          <Remark>{species.name}</Remark>
        </div>
        <SpeciesTableToType className={styles.Table} table={species.table} />
      </div>
      <SpeciesInfo name={t('speciesInfoSynonym')} info={species.synonym} />
      <SpeciesInfo
        name={t('speciesInfoPreviously')}
        info={species.previously}
      />
      <SpeciesInfo name={t('speciesInfoIncludes')} info={species.includes} />
    </div>
  );
};

const SpeciesList = ({ filterRe, favouriteList, setSelectedSpecies }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const listItemsFull = filterSpecies(filterRe, favouriteList);

  const listItems = listItemsFull
    .slice(0, 100)
    .map(([key, species]) => (
      <SpeciesListItem
        key={species.key}
        species={species}
        isFavourite={favouriteList.includes(key)}
        onClick={() => navigate(`/${key}`)}
      />
    ));

  return (
    <div className={styles.SpeciesList}>
      {listItems}
      {listItems.length > 10 ? (
        <span className={styles.Results}>
          {t('speciesListShowingCount', {
            nItems: listItems.length,
            nTotal: listItemsFull.length,
          })}
        </span>
      ) : null}
      {listItemsFull.length !== listItems.length ? (
        <span className={styles.Results}>{t('speciesListNotShowingAll')}</span>
      ) : null}
    </div>
  );
};

const Home = () => {
  const [filter, setFilter] = useState('');
  const [favouriteList] = useState(
    JSON.parse(localStorage.getItem('favourites')) || []
  );
  const { t } = useTranslation();

  const filterRe = filter
    ? new RegExp(
        filter
          .replace('(', '\\(')
          .replace(')', '\\)')
          .replace('.', '\\.')
          .replace('*', '.+')
          .replace('?', '.'),
        'i'
      )
    : null;

  return (
    <div className={styles.Home}>
      <LanguageSelect />

      <div className={styles.Header}>
        <img src="/ista_logo_light.svg" alt="ISTA Logo" />
        <span>{t('homeAppTitle1')}</span>
        <span>{t('homeAppTitle2')}</span>
      </div>
      <div className={styles.FilterInput}>
        <label>
          {t('homeFilterLabel')}
          <input
            placeholder={t('homeFilterInputPlaceholder')}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            autoFocus
          />
        </label>
      </div>
      <SpeciesList {...{ filterRe, favouriteList }} />
      {filterRe ? (
        <FlexSpacer />
      ) : (
        <div className={styles.Credits}>
          {t('homeCredits')
            .split('\n')
            .map((l, i) => (
              <span key={i}>{l}</span>
            ))}
        </div>
      )}
      <div className={makeClass([styles.Credits, styles.Help])}>
        <span>{t('homeSuggestionsEmail')}</span>
        <a href="mailto:sampling.calculator@ista.ch">
          sampling.calculator@ista.ch
        </a>
        <span title={process.env.REACT_APP_GIT_STATE === 'dirty' ? 'Build includes uncommitted modifications' : null}>
          Version: {process.env.REACT_APP_VERSION}
          ({process.env.REACT_APP_GIT_HASH}{process.env.REACT_APP_GIT_STATE === 'dirty' ? '+' : null})
        </span>
      </div>
    </div>
  );
};

export default Home;
