import React from 'react';
import { useTranslation } from 'react-i18next';

const Time = ({ seconds, className }) => {
  const { t } = useTranslation();

  const isValid = seconds && Number.isFinite(seconds) && !Number.isNaN(seconds);

  const hours = isValid ? Math.floor(seconds / 3600) : 0;
  const minutes = isValid ? Math.floor((seconds % 3600) / 60) : 0;
  const dSeconds = isValid ? Math.ceil((seconds % 3600) % 60) : 0;

  const text = t('autoSamplerDuration', { hours, minutes, seconds: dSeconds });

  return (
    <>
      <span className={className}>{text}</span>
      <span></span>
    </>
  );
};

export default Time;
