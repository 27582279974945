import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import LANGUAGES from './data/languages.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: LANGUAGES,
    fallbackLng: 'en',
  });

// This is globally loaded via a script tag in public/index.html
window.plausible('Language', {props:{lang: i18n.language}});
