import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './ScaledInput.module.scss';
import { makeClass } from './utils';

const LockButton = ({ locked, onClick }) => (
  <button className={makeClass([styles.End], locked)} onClick={onClick}>
    <FontAwesomeIcon icon={faCalculator} />
  </button>
);

export default LockButton;
