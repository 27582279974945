import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ACTIONS, stateContext } from '../../../../reducer';

import styles from './OpenWizard.module.scss';

const OpenWizard = () => {
  const { dispatch } = useContext(stateContext);
  const { t } = useTranslation();

  return (
    <div className={styles.OpenWizard}>
      <button
        onClick={() =>
          dispatch({ action: ACTIONS.WIZARD_SHOWING, payload: true })
        }
      >
        {t('seedLotHomogeneousHeterogeneous')}
      </button>
    </div>
  );
};

export default OpenWizard;
