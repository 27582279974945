import React from 'react';
import { useTranslation } from 'react-i18next';

import { ValueGroup } from './components/ValueGroups';

const ContainerSampleValueGroup = ({ label, value }) =>
  value === 0 ? null : <ValueGroup {...{ label, value }} />;

const sumArray = arr => arr.reduce((a, b) => a + b, 0);

const calculateSamplingIntensity = (nContainers, minSamples) => {
  if (!nContainers || !minSamples) {
    return [minSamples, 0, 0, 0];
  }

  if (nContainers >= minSamples) {
    return [minSamples, 0, 0, 0];
  }

  let minTotalSamples = nContainers ** 4;
  let minCombination = [minSamples, 0, 0, 0];

  for (let quad = 0; quad <= nContainers; quad++) {
    for (let triple = 0; triple <= nContainers; triple++) {
      for (let double = 0; double <= nContainers; double++) {
        for (let single = 0; single <= nContainers; single++) {
          const combination = [single, double, triple, quad];

          if (sumArray(combination) !== nContainers) {
            continue;
          }

          const samples = sumArray([single, double * 2, triple * 3, quad * 4]);

          if (samples === minSamples) {
            return combination;
          }

          if (samples >= minSamples && samples < minTotalSamples) {
            minTotalSamples = samples;
            minCombination = combination;
          }
        }
      }
    }
  }

  return minCombination;
};

const SamplingIntensity = ({ value: { nContainers, minSamples } }) => {
  const [
    nContainersSingleSampled,
    nContainersDoubleSampled,
    nContainersTripleSampled,
    nContainersQuadrupleSampled,
  ] = calculateSamplingIntensity(nContainers, minSamples);
  const { t } = useTranslation();

  return (
    <>
      <ContainerSampleValueGroup
        label={t('containersToBeSampledOnce')}
        value={nContainersSingleSampled}
      />
      <ContainerSampleValueGroup
        label={t('containersToBeSampledTwice')}
        value={nContainersDoubleSampled}
      />
      <ContainerSampleValueGroup
        label={t('containersToBeSampledThreeTimes')}
        value={nContainersTripleSampled}
      />
      <ContainerSampleValueGroup
        label={t('containersToBeSampledFourTimes')}
        value={nContainersQuadrupleSampled}
      />
    </>
  );
};

export default SamplingIntensity;
