import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Remark } from 'react-remark';

import LocaleNumber from '../../../components/LocaleNumber';
import { makeClass } from '../../../components/utils';
import { SEED_TYPE } from '../reducer';
import PureSeedDefinitionModal from './PureSeedDefinitionModal';
import styles from './SpeciesInfo.module.scss';

const SpeciesInfoRowNumber = ({ label, value, scalar, unit }) => {
  const { t } = useTranslation();
  unit = unit || value?.unit;
  value = value && value.value ? value.value : undefined;

  return value !== undefined ? (
    <>
      <span className="Label">{label}</span>
      <LocaleNumber
        className="Content"
        value={isNaN(value) ? value : value * (scalar || 1)}
      />
      <span>{value && unit ? t(unit) : null}</span>
    </>
  ) : null;
};

const SpeciesInfoRowString = ({
  label,
  value,
  extra,
  onClick,
  labelClassNames = [],
  valueClassNames = [],
}) => {
  return value !== undefined ? (
    <>
      <span
        className={makeClass(['Label', ...labelClassNames])}
        onClick={onClick}
      >
        {label}
      </span>
      <span
        className={makeClass(['Content', ...valueClassNames])}
        onClick={onClick}
      >
        {value}
      </span>
      <div onClick={onClick}>{extra}</div>
    </>
  ) : null;
};

const SpeciesInfoRowChaffy = ({ species }) => {
  const { t } = useTranslation();
  const isChaffy = species.chaffy
    ? t('speciesInfoChaffyYes')
    : t('speciesInfoChaffyNo');

  return (
    <SpeciesInfoRowString label={t('speciesInfoChaffy')} value={isChaffy} />
  );
};

const SpeciesInfoGenusFamily = ({ species }) => {
  const { t } = useTranslation();
  const genusShort = species.genus.split(' ')[0];

  return (
    <>
      <SpeciesInfoRowString
        label={t('speciesInfoGenus')}
        value={<Remark>{genusShort}</Remark>}
        valueClassNames={[styles.Genus]}
      />
      <SpeciesInfoRowString
        label={t('speciesInfoFamily')}
        value={species.family}
      />
    </>
  );
};

const SpeciesInfoPSD = ({ species }) => {
  const [PSDModalIsShowing, setPSDModalIsShowing] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <SpeciesInfoRowString
        label={t('speciesInfoPSD')}
        value={species.pure_seed_definition}
        extra={
          <FontAwesomeIcon
            icon={faInfoCircle}
            title="PSD"
            style={{ fontSize: '1.25em', cursor: 'pointer' }}
          />
        }
        onClick={() => setPSDModalIsShowing(true)}
      />
      {PSDModalIsShowing ? (
        <PureSeedDefinitionModal
          species={species}
          onClose={() => setPSDModalIsShowing(false)}
        />
      ) : null}
    </>
  );
};

const ContainerSpeciesInfoValues = ({ species, openPSDModal }) => {
  const { t } = useTranslation();
  return (
    <>
      <SpeciesInfoGenusFamily species={species} />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeight')}
        value={species.max_lot_weight}
        scalar={1 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeightSafe')}
        value={species.max_lot_weight}
        scalar={1.05 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeightApproved')}
        value={species.approved_max_lot_weight}
        scalar={1 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeightApprovedSafe')}
        value={species.approved_max_lot_weight}
        scalar={1.05 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMinSubmittedSampleWeight')}
        value={species.min_submitted_general}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMinSubmittedSampleMoisture')}
        value={species.min_submitted_moisture}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMinWorkingSamplePurity')}
        value={species.min_working_purity}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMinWorkingSampleOSD')}
        value={species.min_working_other}
      />
      <SpeciesInfoPSD species={species} />
      <SpeciesInfoRowChaffy species={species} />
    </>
  );
};

const CoatedSpeciesInfoValues = ({ species }) => {
  const { t } = useTranslation();
  return (
    <>
      <SpeciesInfoGenusFamily species={species} />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeight')}
        value={{ value: 40000000, unit: t('unitG') }}
        scalar={1 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeightSafe')}
        value={{ value: 40000000, unit: t('unitG') }}
        scalar={1.05 / 1000}
        unit={t('unitKG')}
      />
      <span className={styles.Span3Columns}>{t('speciesInfoOR')}</span>
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotSize')}
        value={{ value: 1e9, unit: t('unitSeeds') }}
      />

      <SpeciesInfoRowNumber
        label={t('speciesInfoPurityTSWGermination')}
        value={{ value: 2500, unit: t('unitSeeds') }}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoOtherSeeds')}
        value={{ value: 10000, unit: t('unitSeeds') }}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoOSD')}
        value={{ value: 25000, unit: t('unitSeeds') }}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoSizeGrading')}
        value={{ value: 5000, unit: t('unitSeeds') }}
      />
      <SpeciesInfoPSD species={species} />
      <SpeciesInfoRowChaffy species={species} />
    </>
  );
};

const TapesSpeciesInfoValues = ({ species }) => {
  const { t } = useTranslation();
  return (
    <>
      <SpeciesInfoGenusFamily species={species} />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeight')}
        value={{ value: 40000000, unit: t('unitG') }}
        scalar={1 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeightSafe')}
        value={{ value: 40000000, unit: t('unitG') }}
        scalar={1.05 / 1000}
        unit={t('unitKG')}
      />
      <span className={styles.Span3Columns}>{t('speciesInfoOR')}</span>
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotSize')}
        value={{ value: 1e9, unit: t('unitSeeds') }}
      />

      <SpeciesInfoRowNumber
        label={t('speciesInfoVerificationOfSpecies')}
        value={{ value: 300, unit: t('unitSeeds') }}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoGermination')}
        value={{ value: 2000, unit: t('unitSeeds') }}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoPurityAnalysis')}
        value={{ value: 2500, unit: t('unitSeeds') }}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoOtherSeeds')}
        value={{ value: 10000, unit: t('unitSeeds') }}
      />
      <SpeciesInfoPSD species={species} />
      <SpeciesInfoRowChaffy species={species} />
    </>
  );
};

const HeterogeneitySpeciesInfoValues = ({ species }) => {
  const { t } = useTranslation();
  return (
    <>
      <SpeciesInfoGenusFamily species={species} />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeight')}
        value={species.max_lot_weight}
        scalar={1 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMaxLotWeightSafe')}
        value={species.max_lot_weight}
        scalar={1.05 / 1000}
        unit={t('unitKG')}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMinSubmittedSampleWeight')}
        value={species.min_submitted_general}
        scalar={0.5}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMinWorkingSamplePurity')}
        value={{ value: 1000, unit: t('unitSeeds') }}
      />
      <SpeciesInfoRowNumber
        label={t('speciesInfoMinWorkingSampleOSD')}
        value={{ value: 2500, unit: t('unitSeeds') }}
      />
      <SpeciesInfoPSD species={species} />
      <SpeciesInfoRowChaffy species={species} />
    </>
  );
};

const SpeciesSwitch = ({ species, heterogeneityMode, seedType }) => {
  if (heterogeneityMode) {
    return <HeterogeneitySpeciesInfoValues {...{ species }} />;
  }

  if (seedType === SEED_TYPE.COATED) {
    return <CoatedSpeciesInfoValues {...{ species }} />;
  }

  if (seedType === SEED_TYPE.TAPES) {
    return <TapesSpeciesInfoValues {...{ species }} />;
  }

  return <ContainerSpeciesInfoValues {...{ species }} />;
};

const SpeciesInfo = memo(({ species, heterogeneityMode, seedType }) => {
  const [showSpeciesInfo, setShowSpeciesInfo] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.SpeciesInfo}>
        <div
          className={makeClass(
            ['ValueGroups', styles.BoarderBox],
            !showSpeciesInfo,
            styles.Hide
          )}
        >
          <SpeciesSwitch {...{ species, heterogeneityMode, seedType }} />

          <span className={styles.Disclaimer}>
            {t('speciesInfoDisclaimer', {
              year: new Date().getFullYear(),
            })}
          </span>
        </div>

        <button
          className={makeClass([], showSpeciesInfo, styles.Show)}
          onClick={() => setShowSpeciesInfo(~showSpeciesInfo)}
        >
          {showSpeciesInfo ? t('speciesInfoHide') : t('speciesInfoShow')}
        </button>
      </div>
    </>
  );
});

export default SpeciesInfo;
