import React from 'react';
import { useTranslation } from 'react-i18next';

export const formatNumber = (value, languageCode) => {
  const numberFormat = new Intl.NumberFormat(languageCode);

  if (value === null || isNaN(value)) {
    return '';
  }

  let formattedValue = numberFormat.format(value);

  return formattedValue;
};

const LocaleNumber = ({ className, value }) => {
  const { i18n } = useTranslation();

  return (
    <span className={className}>{formatNumber(value, i18n.language)}</span>
  );
};

export default LocaleNumber;
