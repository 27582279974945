import React from 'react';
import { useTranslation } from 'react-i18next';

import LocaleNumber from '../../../../../../components/LocaleNumber';
import { makeClass } from '../../../../../../components/utils';

export const ValueGroup = ({ bold, big, label, value, unit }) => {
  const labelClass = makeClass([
    'Label',
    bold ? 'Bold' : null,
    big ? 'Big' : null,
  ]);
  const contentClass = makeClass([
    'Content',
    bold ? 'Bold' : null,
    big ? 'Big' : null,
  ]);

  return (
    <>
      <span className={labelClass}>{label}</span>
      <LocaleNumber className={contentClass} value={value} />
      <span>{unit}</span>
    </>
  );
};

export const PrimarySamplesValueGroup = ({ value }) => {
  const { t } = useTranslation();

  return (
    <ValueGroup bold big label={t('minNumberOfPrimarySamples')} value={value} />
  );
};
