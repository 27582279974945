import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FlexSpacer from '../../../../components/FlexSpacer';
import { formatNumber } from '../../../../components/LocaleNumber';
import RefuseSampling from '../../../../components/RefuseSampling';
import { stateContext } from '../../reducer';
import ContainerValueInputs from './containers/components/ContainerValueInputs';
import OpenWizard from './containers/components/OpenWizard';
import { ContainersGT100kg } from './containers/ContainersGT100kg';
import {
  ContainersGTE15kgAndLTE100kg,
  ContainersLT15kg,
} from './containers/ContainersLTE100kg';
import styles from './Treated.module.scss';

const NoContainer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.NoContainer}>
      <span>{t('treatedNoContainer')}</span>
    </div>
  );
};

const Container = ({ nContainers, containerWeight, lotWeight }) => {
  if (lotWeight === null) {
    return <NoContainer />;
  }

  if (containerWeight < 15000) {
    return <ContainersLT15kg value={{ lotWeight }} />;
  }

  if (containerWeight <= 100000) {
    return <ContainersGTE15kgAndLTE100kg value={{ nContainers }} />;
  }

  return <ContainersGT100kg value={{ lotWeight, nContainers }} />;
};

const Treated = () => {
  const { species, inputs } = useContext(stateContext);
  const { t } = useTranslation();
  const { nContainers, containerWeight, lotWeight } = inputs;

  const maxLotWeight = species.max_lot_weight.value * 1.05;
  const approvedMaxLotWeight = species.approved_max_lot_weight
    ? species.approved_max_lot_weight.value * 1.05
    : null;
  const isPoaceae = approvedMaxLotWeight !== null;

  const exceedsMax = lotWeight && lotWeight > maxLotWeight;
  const exceedsApprovedMax =
    isPoaceae && lotWeight && lotWeight > approvedMaxLotWeight;

  const showExceedsMaxWarning = exceedsMax;
  const showContainerValues =
    (isPoaceae && !exceedsApprovedMax) || (!isPoaceae && !exceedsMax);
  const showPoaceaeExceedsMaxWarning =
    isPoaceae && exceedsMax && !exceedsApprovedMax;

  const refuseSamplingStyle = exceedsApprovedMax
    ? 'Error'
    : isPoaceae && exceedsMax
    ? 'Warning'
    : 'Error';

  const containerWeightLabel =
    containerWeight === null
      ? null
      : containerWeight < 15000
      ? t('treatedContainerWeightLabelLT15kg')
      : containerWeight <= 100000
      ? t('treatedContainerWeightLabelGTE15LTE100kg')
      : t('treatedContainerWeightLabelGT100kg');

  return (
    <>
      <ContainerValueInputs {...{ containerWeightLabel }} />

      <hr />

      {showExceedsMaxWarning && (
        <RefuseSampling style={refuseSamplingStyle}>
          <span>
            {t(
              exceedsApprovedMax
                ? 'treatedExceedsMaxApproved'
                : 'treatedExceedsMaxNotApproved',
              {
                maxLotWeight: formatNumber(maxLotWeight / 1000),
                approvedMaxLotWeight: formatNumber(approvedMaxLotWeight / 1000),
              }
            )}
          </span>
        </RefuseSampling>
      )}

      {showPoaceaeExceedsMaxWarning && (
        <div className={styles.PoaceaeExceedsMaxHeading}>
          <span>{t('poaceaeExceedsMaxHeading')}</span>
        </div>
      )}

      {showContainerValues && (
        <Container
          {...{
            nContainers,
            containerWeight,
            lotWeight,
          }}
        />
      )}

      <FlexSpacer />
      <OpenWizard />
    </>
  );
};

export default Treated;
