import React from 'react';

const NumberInput = ({
  className,
  style,
  value,
  onChange,
  disabled,
  min = undefined,
  max = undefined,
}) => {
  return (
    <input
      className={className}
      style={style}
      inputMode="decimal"
      min={min}
      max={max}
      value={value === null || value === undefined ? '' : value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
};

export default NumberInput;
