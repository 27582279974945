import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Remark } from 'react-remark';
import { useNavigate, useParams } from 'react-router-dom';

import ExceedsMaxWizard from '../../components/ExceedsMaxWizard';
import Help from '../../components/help/Help';
import SPECIES from '../../data/species.json';
import ContainerTypeButtons from './ContainerTypeButtons/ContainerTypeButtons';
import FormSwitch from './FormSwitch/FormSwitch';
import reducer, {
  ACTIONS,
  SAMPLED_FROM,
  initState,
  stateContext,
} from './reducer';
import SampledFromButtons from './SampledFromButtons/SampledFromButtons';
import SpeciesInfo from './SpeciesInfo/SpeciesInfo';
import styles from './SpeciesPage.module.scss';

const SingleExtraInfo = ({ name, info }) => {
  if (info === undefined) {
    return null;
  }

  return (
    <>
      <span>{name}</span>
      <div className={styles.SingleExtraInfoInfo} dir="ltr">
        <Remark>{info}</Remark>
      </div>
    </>
  );
};

const ExtraInfo = ({ species: { synonym, previously, includes } }) => {
  const { t } = useTranslation();

  if (
    synonym === undefined &&
    previously === undefined &&
    includes === undefined
  ) {
    return null;
  }

  return (
    <div className={styles.ExtraInfo}>
      <SingleExtraInfo name={t('speciesInfoSynonym')} info={synonym} />
      <SingleExtraInfo name={t('speciesInfoPreviously')} info={previously} />
      <SingleExtraInfo name={t('speciesInfoIncludes')} info={includes} />
    </div>
  );
};

const SpeciesName = ({ name }) => (
  <div className={styles.Header} dir="ltr">
    <Remark>{name}</Remark>
  </div>
);

const SpeciesPage = () => {
  const { species_slug } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(
    reducer,
    SPECIES[species_slug],
    initState
  );
  const { t } = useTranslation();

  return (
    <stateContext.Provider value={{ ...state, dispatch }}>
      <Help
        helpType={state.helpType}
        onClose={() => dispatch({ action: ACTIONS.HELP_TYPE, payload: null })}
      />
      <div className={state.isFavourite ? styles.Favourite : null}>
        <SpeciesName name={state.species.name} />
        <ExtraInfo species={state.species} />
      </div>
      <div className={styles.HeaderButtons}>
        <button onClick={() => navigate('/')}>
          {t('speciesSelectSpecies')}
        </button>
        <button onClick={() => dispatch({ action: ACTIONS.DEFAULT_INPUTS })}>
          {t('speciesResetInputs')}
        </button>
        <button onClick={() => dispatch({ action: ACTIONS.TOGGLE_FAVOURITE })}>
          {state.isFavourite
            ? t('speciesRemoveFavourite')
            : t('speciesSaveFavourite')}
        </button>
      </div>

      <hr />

      <SpeciesInfo {...state} />

      <hr />

      <SampledFromButtons
        sampledFrom={state.sampledFrom}
        heterogeneityMode={state.heterogeneityMode}
        dispatch={dispatch}
      />
      {state.sampledFrom === SAMPLED_FROM.CONTAINER ? (
        <ContainerTypeButtons
          seedType={state.seedType}
          heterogeneityMode={state.heterogeneityMode}
          dispatch={dispatch}
        />
      ) : null}

      <hr />

      <FormSwitch {...state} />

      {state.wizardShowing ? <ExceedsMaxWizard /> : null}
    </stateContext.Provider>
  );
};

export default SpeciesPage;
