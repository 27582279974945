import React from 'react';
import { useTranslation } from 'react-i18next';
import { Remark } from 'react-remark';

import CloseButton from '../../../../components/CloseButton';
import Modal from '../../../../components/Modal';
import styles from './PureSeedDefinitionModal.module.scss';

const Header = ({ species }) => {
  const { t } = useTranslation();
  const index = species.pure_seed_definition;

  return (
    <div className={styles.HeaderContainer}>
      <span className={styles.Header}>{t('pureSeedDefinitionHeader')}</span>
      <div className={styles.SubHeader}>
        <Remark>
          {t('pureSeedDefinitionSubHeader', {
            number: index,
            genus: species.genus,
          })}
        </Remark>
      </div>
    </div>
  );
};

const Definition = ({ species }) => {
  const { t } = useTranslation();
  const index = species.pure_seed_definition;

  const text =
    t(`pureSeedDefinition${index}`) || t('pureSeedDefinitionNotFound');

  return (
    <div className={styles.Definition}>
      <Remark>{text}</Remark>
    </div>
  );
};

const PureSeedDefinitionModal = ({ species, onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div className={styles.PureSeedDefinitionModal}>
        <Header species={species} />
        <Definition species={species} />
        <div className={styles.BtnContainer}>
          <CloseButton onClose={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default PureSeedDefinitionModal;
