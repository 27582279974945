import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LANGUAGES from '../../data/languages.json';
import CloseButton from '../CloseButton';
import Modal from '../Modal';
import c from './LanguageSelect.module.scss';

const Lang = ({ localName, englishName, onClick }) => (
  <button className={c.Lang} onClick={onClick} title={localName}>
    <span className={c.LocalName}>{localName}</span>
    <span className={c.EnglishName}>{englishName}</span>
  </button>
);

const DetectLanguageButton = ({ setLanguage }) => {
  const { t } = useTranslation();

  const onClick = (e) =>
    e.shiftKey ? setLanguage('cimode') : setLanguage(undefined);

  return (
    <Lang
      localName={t('langSelectDetect')}
      englishName="Detect Language"
      onClick={onClick}
    />
  );
};

const LanguageList = ({ setLanguage }) => {
  const listItems = Object.values(LANGUAGES)
    .sort((a, b) => a.englishName.localeCompare(b.englishName))
    .map((l) => (
      <Lang
        key={l.code}
        localName={l.localName}
        englishName={l.englishName}
        onClick={() => setLanguage(l.code)}
      />
    ));

  return (
    <div className={c.LangList}>
      <DetectLanguageButton setLanguage={setLanguage} />
      {listItems}
    </div>
  );
};

const LanguageSelectModal = ({ isShowing, onClose, setLanguage }) => {
  const { t } = useTranslation();

  if (!isShowing) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <div className={c.LanguageSelect}>
        <div className={c.Title}>
          <span>{t('langSelectTitle')}</span>
        </div>
        <LanguageList setLanguage={setLanguage} />
        <CloseButton classNames={[c.Close]} onClose={onClose} />
      </div>
    </Modal>
  );
};

const LanguageSelectButton = ({ onOpen }) => {
  const { t } = useTranslation();

  return (
    <button
      className={c.LanguageButton}
      title={t('homeLanguage')}
      onClick={onOpen}
    >
      <div>
        <span>Select</span>
        <span>Language</span>
      </div>
      <FontAwesomeIcon icon={faLanguage} size="2x" />
    </button>
  );
};

const LanguageSelect = () => {
  const [isShowing, setIsShowing] = useState(false);
  const { i18n } = useTranslation();

  const setLanguage = (language) => {
    if (language === undefined) {
      localStorage.removeItem('i18nextLng');
    }
    i18n.changeLanguage(language);
    setIsShowing(false);
  };

  const onOpen = () => setIsShowing(true);
  const onClose = () => setIsShowing(false);

  return (
    <>
      <LanguageSelectButton onOpen={onOpen} />
      <LanguageSelectModal
        {...{
          isShowing,
          onClose,
          setLanguage,
        }}
      />
    </>
  );
};

export default LanguageSelect;
