import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import LockedNumberInput from '../../../../../../components/LockedNumberInput';
import ScaledInput from '../../../../../../components/ScaledInput';
import { ACTIONS, stateContext } from '../../../../reducer';

export const NContainersInput = ({ nContainers, locked, dispatch }) => {
  const { t } = useTranslation();

  return (
    <div className="InputGroup">
      <span className="Label">{t('containerNumberOfContainers')}</span>
      <LockedNumberInput
        value={nContainers}
        onChange={v => dispatch({ action: ACTIONS.N_CONTAINERS, payload: v })}
        min="1"
        locked={locked && locked.nContainers}
        onLocked={() => dispatch({ action: ACTIONS.LOCKED_N_CONTAINERS })}
      />
    </div>
  );
};

const ContainerWeightInput = ({
  containerWeightLabel,
  containerWeight,
  locked,
  dispatch,
}) => {
  const { t } = useTranslation();

  return (
    <div className="InputGroup">
      <span className="Label">
        {t('containerContainerWeight')} {containerWeightLabel}
      </span>
      <ScaledInput
        value={containerWeight}
        onChange={v =>
          dispatch({ action: ACTIONS.CONTAINER_WEIGHT, payload: v })
        }
        locked={locked && locked.containerWeight}
        onLocked={() => dispatch({ action: ACTIONS.LOCKED_CONTAINER_WEIGHT })}
      />
    </div>
  );
};

export const TotalLotWeightInput = ({ lotWeight, locked, dispatch }) => {
  const { t } = useTranslation();

  return (
    <div className="InputGroup">
      <div className="Label">
        <span>{t('autoSamplerTotalLotWeight')}</span>
      </div>
      <ScaledInput
        value={lotWeight}
        onChange={v => dispatch({ action: ACTIONS.LOT_WEIGHT, payload: v })}
        locked={locked && locked.lotWeight}
        onLocked={() => dispatch({ action: ACTIONS.LOCKED_LOT_WEIGHT })}
      />
    </div>
  );
};

const ContainerValueInputs = ({ containerWeightLabel }) => {
  const {
    inputs: { nContainers, containerWeight, lotWeight, locked },
    dispatch,
  } = useContext(stateContext);

  return (
    <>
      <NContainersInput {...{ nContainers, locked, dispatch }} />
      <ContainerWeightInput
        {...{ containerWeightLabel, containerWeight, locked, dispatch }}
      />
      <TotalLotWeightInput {...{ lotWeight, locked, dispatch }} />
    </>
  );
};

export default ContainerValueInputs;
