import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpCoated = () => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t('helpCoatedTitle')}</h3>
      <p>{t('helpCoatedP1')}</p>

      <h3>{t('helpCoatedEx1h')}</h3>
      <p>{t('helpCoatedEx1p')}</p>

      <h3>{t('helpCoatedEx2h')}</h3>
      <p>{t('helpCoatedEx2p')}</p>
    </>
  );
};

export default HelpCoated;
