import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { makeClass } from '../../../components/utils';
import { ACTIONS, SAMPLED_FROM } from '../reducer';
import styles from './SampledFromButtons.module.scss';

const SampledFromButtons = memo(
  ({ sampledFrom, heterogeneityMode, dispatch }) => {
    const { t } = useTranslation();
    return (
      <div className="InputGroup">
        <span className="Label">{t('sampledFromTitle')}</span>
        <div className={styles.SampledFromButtons}>
          <button
            className={makeClass(
              ['start'],
              sampledFrom === SAMPLED_FROM.CONTAINER
            )}
            onClick={() =>
              dispatch({
                action: ACTIONS.SAMPLED_FROM,
                payload: SAMPLED_FROM.CONTAINER,
              })
            }
          >
            {t('sampledFromContainers')}
          </button>
          <button
            className={makeClass(
              ['end'],
              sampledFrom === SAMPLED_FROM.STREAM
            )}
            disabled={heterogeneityMode}
            onClick={() =>
              dispatch({
                action: ACTIONS.SAMPLED_FROM,
                payload: SAMPLED_FROM.STREAM,
              })
            }
          >
            {t('sampledFromSeedStream')}
          </button>
        </div>
      </div>
    );
  }
);
export default SampledFromButtons;
