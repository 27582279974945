import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpMinSubmitted = () => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t('helpMinSubmittedTitle')}</h3>
      <p>{t('helpMinSubmittedP1')}</p>
    </>
  );
};

export default HelpMinSubmitted;
