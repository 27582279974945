import React from 'react';
import { useTranslation } from 'react-i18next';

import LocaleNumber from '../../../../components/LocaleNumber';

const TapesAndMats = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="ValueGroups">
        <span className="Label">{t('speciesInfoVerificationOfSpecies')}</span>
        <LocaleNumber className="Content" value="300" />
        <span>{t('unitSeeds')}</span>

        <span className="Label">{t('speciesInfoGermination')}</span>
        <LocaleNumber className="Content" value="2000" />
        <span>{t('unitSeeds')}</span>

        <span className="Label">{t('speciesInfoPurityAnalysis')}</span>
        <LocaleNumber className="Content" value="2500" />
        <span>{t('unitSeeds')}</span>

        <span className="Label">{t('speciesInfoOtherSeeds')}</span>
        <LocaleNumber className="Content" value="10000" />
        <span>{t('unitSeeds')}</span>
      </div>
    </>
  );
};

export default TapesAndMats;
