import React from 'react';
import { useTranslation } from 'react-i18next';

import LocaleNumber from '../../../../../components/LocaleNumber';
import { PrimarySamplesValueGroup } from './components/ValueGroups';
import SamplingIntensity from './SamplingIntensity';

export const calculatePrimarySamples = nContainers => {
  if (nContainers === null) return null;
  if (nContainers < 5) return 3 * nContainers;
  if (nContainers < 9) return 2 * nContainers;
  if (nContainers < 16) return nContainers;
  if (nContainers < 31) return 15;
  if (nContainers < 60) return 20;
  return 30;
};

const SamplesPerUnitValueGroup = ({ value }) => {
  const { t } = useTranslation();

  return (
    <>
      <span className="Label">{t('primarySamplesPerSamplingUnit')}</span>
      <LocaleNumber className="Content" value={value} />
      <span></span>
    </>
  );
};

const SamplingUnitsValueGroup = ({ value }) => {
  const { t } = useTranslation();

  return (
    <>
      <span className="Label">{t('samplingUnits')}</span>
      <LocaleNumber className="Content" value={value} />
      <span></span>
    </>
  );
};

export const ContainersLT15kg = ({ value: { lotWeight } }) => {
  const nSamplingUnits = Math.ceil(lotWeight / 100000);
  const minSamples = calculatePrimarySamples(nSamplingUnits);
  const nSamplesPerUnit = minSamples / nSamplingUnits;

  return (
    <div className="ValueGroups">
      <PrimarySamplesValueGroup value={minSamples} />
      {nSamplingUnits > minSamples ? (
        <SamplingIntensity
          value={{ minSamples, nContainers: nSamplingUnits }}
        />
      ) : (
        <SamplesPerUnitValueGroup value={nSamplesPerUnit} />
      )}
      <SamplingUnitsValueGroup value={nSamplingUnits} />
    </div>
  );
};

export const ContainersGTE15kgAndLTE100kg = ({ value: { nContainers } }) => {
  const minSamples = calculatePrimarySamples(nContainers);

  return (
    <div className="ValueGroups">
      <PrimarySamplesValueGroup value={minSamples} />
    </div>
  );
};
